










import { Component, Vue } from "vue-property-decorator";
import { IconsType } from "@/types";
import { mdiHelpCircleOutline } from "@mdi/js";
@Component
export default class BarApp extends Vue {
  private icons: IconsType = {
    help: mdiHelpCircleOutline
  };
  private to = { name: "Help" };
  get showHelp() {
    return this.$route.name !== "Help" && this.$route.name !== "Admin";
  }
}
